<template>
  <v-btn @click.stop="generate" :class="classes" v-bind="$attrs" :icon="icon">
    <v-progress-circular
      :rotate="360"
      :size="30"
      :width="5"
      :value="progress"
      v-if="generating"
    ></v-progress-circular>
    <v-icon :left="!icon" v-else>mdi-download</v-icon>
    <slot></slot>
  </v-btn>
</template>
<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "ReportButton",
  props: {
    icon: { type: Boolean },
    parameters: { type: Object, default: () => {} },
    resource: { type: String },
  },
  data() {
    return {
      generating: false,
      progress: 0,
    };
  },
  computed: {
    classes() {
      const result = ["btn", "btn-default"];
      if (this.generating) result.push("disabled");
      return result;
    },
  },
  methods: {
    download(fileInfo) {
      this.generating = false;
      fileInfo.download();
    },
    error() {
      this.$root.showError("Fehler beim Erzeugen des Berichts.");
      this.generating = false;
    },
    async generate() {
      if (this.generating) return;
      this.progress = 0;
      const parameters = this.parameters;
      if (parameters.inputTitle) {
        parameters.input = prompt(parameters.inputTitle, "0");
        if (!parameters.input) return;
      }
      this.generating = true;
      const params = {
        resource: this.resource,
        data: parameters,
        onProgress: this.updateProgress,
        onDownload: this.download,
        onError: this.error,
      };
      if (!(await this.apiReport(params))) {
        this.generating = false;
      }
    },
    updateProgress(progress) {
      this.progress = progress;
    },
  },
});
</script>
